 <template>
  <div >
     <!--篩選區塊-->
     <el-row>
       <el-button
          style="float: left;"
          size="small"
          type="primary"
          icon="el-icon-tickets"
          v-if="(list!=undefined&&list!=null&&list.length>0)&&(list==undefined||list==null||list.length<=0)"
          @click="exportReport()"
        >{{$t('ExportReport')}}
       </el-button>
   
       <div style="float: left;">
       <!--listAll Export to XLSX-->
       <excel-xlsx
         :data="listXls" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
         v-if="listXls!=undefined&&listXls!=null&&listXls.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/operating-report/counting_record','EXPORT','匯出充電樁請款紀錄統計報表 xlsx')">{{$t('DownloadAllDataXlsx')}}</el-button>
       </excel-xlsx>
       <!--listAll Export to XLSX-->
       </div>
 
       <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">發票開立區間:</span>
        <!--
        <el-date-picker
             v-model="dateRange"
             size="small"
             class="filter-item"
             type="daterange"
             value-format="yyyy-MM-dd"
             range-separator="-"
             :start-placeholder="listQuery.startOn"
             :end-placeholder="listQuery.endOn">  
           </el-date-picker>
          -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startOn">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.endOn">
      </el-date-picker>             
      </div>

      <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">查詢專案:</span>
        <el-select v-model="listQuery.serviceName" placeholder="RA系統服務名稱"
            clearable size="small" class="filter-item-mid" width="140px">
           <el-option v-for="item in serviceNameOption" :key="item.service_name"
                     :label="item.service_name" :value="item.service_name" />
         </el-select>
         <el-select v-model="listQuery.serviceType" placeholder="RA系統服務型態"
            clearable size="small" class="filter-item-mid" width="140px">
           <el-option v-for="item in serviceTypeOption" :key="item.service_type"
                     :label="item.serviceTypeOption" :value="item.service_type" />
         </el-select>
      </div>
      <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">收費管道:</span>
        <!--
        <el-input size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable /> -->
        <el-select size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable>
          <el-option
            v-for="item in platforms"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
     </el-row>
     <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="listXls" >產製時間: {{ reportDate }} </span>
        </el-row>
     <!-- table -->
  

<div style= "margin: 50px 0;">
  
    <el-row> 
       <!-- summary-method="getSummary"  show-summary -->
     <el-table
     :summary-method="getSummary" 
      show-summary
       size="mini"
       v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >     
       <!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="no" label="序號" />
       <el-table-column align="center" prop="customer_name" :show-overflow-tooltip="true"
                         :render-header="renderNameHeaderMethods"  label="客戶名稱" sortable>
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.customer_name != null" class="item" effect="dark" :content="scope.row.customer_name" placement="top-start">
            <span v-if="scope.row.customer_name != null">{{hideName(scope.row.customer_name)}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="hd_number"  label="客戶編號"/>
     <el-table-column align="center" prop="customer_id"  label="證號" :show-overflow-tooltip="true"  :render-header="renderAccountHeaderMethods"  >
      <template slot-scope="scope">
           <el-tooltip class="item" effect="dark" :content="scope.row.customer_id" placement="top-start">
             <span>{{scope.row.customer_id.replace(scope.row.customer_id.substr(5,5),"*****")}}</span>
           </el-tooltip>
         </template>
    </el-table-column>     
       <el-table-column align="center" prop="platform" label="收費管道" /><!--替換:充電站-->
       <el-table-column align="center" prop="invoice_number" label="電信費收據/發票號碼" />  
       <el-table-column align="center" prop="amount" label="總金額" />
       <el-table-column align="center" prop="charge"  label="支出手續費" /><!--替換:充電站-->
       <el-table-column align="center" prop="settle_date" label="款項入帳/退款日期" />
       <el-table-column align="center" prop="invoice_productname" label="品名" />
       <el-table-column align="center" prop="service_name" label="RA系統服務名稱" />
       <el-table-column align="center" prop="service_type" label="RA系統服務型態" />
       <el-table-column align="center" prop="k2_code" label="會計契約編號" />
       <el-table-column align="center" prop="trade_institution" label="交易機構" />
     </el-table> 
     <el-divider content-position="center" >
      <span v-loading="listLoading" style="font-weight: bold; font-size: 1.2em;">合計金額:$  {{statisticsData.total_amount}}  元，手續費: $ {{statisticsData.total_charge}} 元</span>
    </el-divider>
     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
      
</el-row> 
  </div>
   </div>
 </template>
 
 <script>
 import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';
 /* 每隔10min刷新出jwt token*/
 
 
 export default {
     name: 'Status',
     components: {
         Pagination
     },
     mixins: [mixins],
     data() {
         return {
             dialogWidth: 0,
             total: 0,
             serviceNameOption    : undefined,
             serviceTypeOption    : undefined,
             connectorOption  : undefined,
             chargePointOption: undefined,
             platforms: [{
                value: 'Tap',
                label: 'TapPay'
              }, {
                value: 'Line',
                label: 'LinePay'
              }],
             list: [],
             statisticsData: '',
             listAll: [],
             listXls: [],
             listCsv: [],
             listLoading: false,
             listQuery: {
                 //serviceId: this.$store.state.currentAccount.id,
                 //serviceId: this.$store.state.currentAccount.serviceInfo.id,
                // stationId        : undefined,
                 //chargepointId    : undefined,
                 //chargeConnectorId: undefined,
                 startOn:undefined, // this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//30天前
                 endOn:  undefined,// this.$moment().format("YYYY-MM-DD"),
                 serviceName:  undefined,
                 serviceType:  undefined,
                 platForm:  undefined,
                 //queryDays: 31,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 15,
                 //sort: "customer_name,asc",
             },

             size           : undefined,
             paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
             //dateRange      : undefined,
             dateStart        : undefined,
             dateEnd          : undefined,
             //queryDays: undefined,
             dialogSettingVisible: false,
             targetUrl:undefined,
             reportDate: undefined,
             json_fields: {
               "No"   : "no",
               "StationName"     : "stationName",
               "PointName"   : "pointName",
               "Count" : "count",
               "UsageRate"     : "usageRate",
             },
             xlsxColumns : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '序號',
                     field: "no",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '客戶名稱',
                     field: "customer_name",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '客戶編號',
                     field: "hd_number",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '證號',
                     field: "customer_id",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '收費管道',
                     field: "platform",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '電信費收據/發票號碼',
                     field: "invoice_number",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '總金額',
                     field: "amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '支出手續費',
                     field: "charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '款項入帳/退款日期',
                     field: "settle_date",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '品名',
                     field: "invoice_productname",
                 }, 
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: 'RA系統服務名稱',
                     field: "service_name",
                 }, 
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: 'RA系統服務型態',
                     field: "service_type",
                 },                                    
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '會計契約編號',
                     field: "k2_code",
                 },                 
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '交易機構',
                     field: "trade_institution",
                 },                                  
                 ],
             /* 匯出檔名 */
             //filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls",
             //filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls",
             filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_CountingReportAll",
      
       // chartDataExist : false, // 是否有圖表資料
         //   xAxisData      : [],    // x軸 日期時間
           // yAxisData      : [],    // y軸  
           // y2AxisData     : [],    // y軸2        
         };
     },
   created() {
     //定時刷新

     this.dialogWidth = this.setDialogWidth()
     this.getServiceName()
  
   },
   mounted() {
     window.onresize = () => {
       return (() => {
         this.dialogWidth = this.setDialogWidth()
       })()
     }
   },
   beforeDestroy() {
     console.log("Call stopRefreshToken");
     //stopRefreshToken();
   },
   watch: {
     paginationSelectedPage: {
       handler: function(newValue) {
         this.listQuery.page = newValue - 1
       }
     },

     /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
     '$i18n.locale': function(val) {
       console.log('Role組件重載前')
       this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
       console.log('Role組件重載完成')
       },
     /* ----------------------------------------------------------------------------- */
     'listQuery.serviceName': function (val) {
       this.listQuery.page = 0;
       this.listQuery.serviceName = val;
       this.serviceTypeOption=null;
       this.listQuery.serviceType=null;
       if(val){ // http://localhost:6783/api/v1/report/AccountRecordsServiceType?serviceName=evems
        this.targetUrl ="/api/v1/report/AccountRecordsServiceType?serviceName="+val;
        this.axios.get(this.targetUrl).then(
           res => {
           //console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
           this.serviceTypeOption=res.data;
           }
          );
       }
       this.resetPage(this.getList);
       this.listXls= [];

       //this.connectorOption=null;
      },
      'listQuery.platForm': function (val) {
       this.listQuery.page = 0;
       this.listQuery.platForm = val;
       this.resetPage(this.getList);
       this.listXls= [];
       //this.connectorOption=null;
      },
      'listQuery.serviceType': function (val) {
       this.listQuery.page = 0;
       this.resetPage(this.getList);
       this.listXls= [];
       //this.connectorOption=null;
      },
    dateStart: function(val) {
      if (val) {
        this.listQuery.startOn = this.dateStart
      } else {
        this.listQuery.startOn = undefined
      }
      this.listQuery.page = 0;
      this.listXls= [];
      //this.listCsv= [];
       this.resetPage(this.getList)   
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.endOn = this.dateEnd
      } else {
        this.listQuery.endtOn = undefined
      }
      this.listQuery.page = 0;
      this.listXls= [];
      //this.listCsv= [];
       this.resetPage(this.getList)  
    },      
      },
   computed: {
    
  },
   methods: {
    getServiceName() {
       // http://localhost:6783/api/v1/report/AccountRecordsServiceName

       this.axios.get("/api/v1/report/AccountRecordsServiceName").then(
            res => {
           this.serviceNameOption=res.data;
          // if(this.serviceNameOption != undefined){
            //this.getServiceType() 
          // }
           }
          );
     },
     getServiceType() {
       //  (http://localhost:6783/api/v1/report/AccountRecordsServiceType?serviceName=evems)
       this.axios.get("/api/v1/report/AccountRecordsServiceType").then(
           res => {
           //console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
           this.serviceTypeOption=res.data;
           }
          );
     },
    exportToCSV() {
    //  this.logOperate('report','/report/usage','EXPORT','匯出充電樁使用率統計報表 csv');

const csvContent = this.convertToCSV(this.listCsv); //utf-8 

const link = document.createElement("a");


// "\ufeff"是为了解决CSV中文
let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
let url = URL.createObjectURL(blob);
link.href = url;
link.setAttribute('download', this.filenameAllXlsx);
link.click();
},

convertToCSV(data) {
const columnDelimiter = ",";
const lineDelimiter = "\n";
const keys = Object.keys(data[0]);

let result = "";
result += keys.join(columnDelimiter);
result += lineDelimiter;

data.forEach(item => {
  let row = "";
  keys.forEach(key => {
    row += item[key] + columnDelimiter;
  });
  result += row.slice(0, -1);
  result += lineDelimiter;
});

return result;
},

     getList() {
       let number = this.page
       let size   = this.size

       this.listLoading = true
       
      this.targetUrl ="/api/v1/report/AccountRecords"; //?startOn=2024-07-28
 
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
         //this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         number = res.data.number
         size   = res.data.size

         let output = this.list.map( ( item , index ) => {
             item.no = index + number*size +1
             if(!item.customer_id) item.customer_id = "沒有證號"              
             return item
         })   
         /*
         const newItem = {
        no: '總計',
        customer_name: '',
        hd_number: undefined,
        platform: '',
        invoice_number: '',
        amount: this.statisticsData.total_amount,
        charge: this.statisticsData.total_charge,
        settle_date: '',
        invoice_productname: ''
      };
      this.list.push(newItem);     
      console.log("list",this.list) */
       })
       this.targetUrl ="/api/v1/report/AccountRecordsTotal"; //?startOn=2024-07-28 http://localhost:6783/api/v1/report/AccountRecordsTotal

this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
  this.statisticsData = res.data
 })
 

 this.exportReport();
this.listLoading = false
     },

       exportReport(){
        let number = this.page
        let size   = this.size
       this.size          = this.listQuery.size
       this.listQuery.page=0
       this.listQuery.size=100000
 
       this.targetUrl ="/api/v1/report/AccountRecords"; //?startOn=2024-07-28
 

  this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
   this.listXls = res.data.content
   number = res.data.number
   size   = res.data.size

   let output = this.listXls.map( ( item , index ) => {
       item.no = index + number*size +1             
       return item
   })       
   const newItem = {
        no: '總計',
        customer_name: '',
        hd_number: '',
        customer_id:'',
        platform: '',
        invoice_number: '',
        amount: this.statisticsData.total_amount,
        charge: this.statisticsData.total_charge,
        settle_date: '',
        invoice_productname: '',
        service_name:'',
        service_type:'',
        k2_code: '',
        trade_institution:'',        
      };
      this.listXls.push(newItem);  
 })

       this.listQuery.size=this.size
       //this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
       //this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
       this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_CountingReportAll"
      // this.listQuery.size=15
     },

     getSummary({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小計';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
           const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          //if(index === 5){
          //  sums[index] = this.statisticsData.total_amount;
         // }
          //if(index === 6){
           // sums[index] = this.statisticsData.total_charge;
         // }
         if(index === 3){
            sums[index] = '';
          }         
          if(index === 8){
            sums[index] = '';
          }
          //sums[index] += ' 分';
          if(index === 9){
            sums[index] = '';
          }
          if(index === 10){
            sums[index] = '';
          }  
          if(index === 11){
            sums[index] = '';
          }           
          if(index === 12){
            sums[index] = '';
          }   
          if(index === 13){
            sums[index] = '';
          }         
        } else {
          sums[index] = '';
        }
      });
      console.log("sums",sums)
      return sums;
    },
   }
 }
 
 </script>
 